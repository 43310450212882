import { IonGrid, IonRow, IonCol, IonCard, IonContent, IonList, IonLabel, IonButton, IonListHeader, IonHeader, IonItem,IonIcon, IonPage, IonTitle, IonToolbar, IonCardContent, IonChip, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import {DataContext} from '../context/DataContext';
import { listOutline } from 'ionicons/icons';
import { dbQuery } from '@saint/sqlite-worker';
import { bworkerQuery } from '@saint/fetch-worker';
import axios from 'axios';

function KachelLieferantenAusschreibung(props:any) {

    const [localShowAusschreibung, setLocaShowAusschreibung] = useState(false)

    const [localLieferantenArtikelHTML, setLocalLieferantenArtikelHTML] = useState(<IonItem>Lade Daten...</IonItem>)
    const [localShowUebersichtAuschreibungen, setLocalShowUebersichtAuschreibungen] = useState(<IonItem>keine Ausschreibung vorhaden</IonItem>)
const {
    appChosenKundenId,
    appChosenAppChange,
    server,
    appToken,
    appAusschreibungData,
    appAusschreibungDataChange
} = useContext(DataContext);

useEffect( () => { 
    if(appChosenKundenId){
        var config = {
            method: 'post',
            url: server + `/funktionen/api2/lieferanten/get_ausschreibung.php?kunden_id=`+appChosenKundenId,
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${appToken}`,
            },
          };
        axios(config)
        .then(
          async function( response: { data: { fieldnames: []; data: [];  } , status: Number} ) {
            if(response.status===200){
                console.log(response.data.data);
                if(response.data.data){
                    appAusschreibungDataChange(response.data.data)
                    setLocaShowAusschreibung(true);
                }
                
            }
            else {
                setLocaShowAusschreibung(false);
            }
          }
        )
        
    }
    
},[appChosenKundenId])

useEffect( () => { 
    console.log('appAusschreibungData',appAusschreibungData)
    setLocalShowUebersichtAuschreibungen(
        appAusschreibungData.data &&
        appAusschreibungData.data.length > 0
        ?
            appAusschreibungData.data.map((ausschreibung: any) => {
                                    return (
                                        <IonItem>
                                            <IonLabel slot="start">{ausschreibung.bestellung_bezeichnung}</IonLabel>
                                            <IonLabel slot="end">{new Date(ausschreibung.bestellung_abgabe).toLocaleDateString('de-DE',{ weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</IonLabel>
                                        </IonItem>
                                        );
                                })
          : "nix"  
    )

},[appAusschreibungData])

    return (
        localShowAusschreibung 
        ?
        // <IonPage>
        
        <IonCard key="main" class="item-trans"  style={{ minHeight: '400px', maxHeight:'400px',color: `#ffffff`, backgroundColor: `#0B4C5F`}}>
            <IonCardHeader >
                <IonCardHeader style={{color: `#000000`, backgroundColor: `#ffffff`,fontSize:"2em"}}><IonIcon  icon={listOutline} ></IonIcon>Preisanfragen</IonCardHeader>
                <IonCardSubtitle><IonButton size="small" routerLink ={'Ausschreibung'} onClick={()=>appChosenAppChange(<><IonIcon  icon={listOutline} ></IonIcon>Preisanfragen öffnen</>)} expand="block">Preisanfragen öffnen</IonButton></IonCardSubtitle>
            </IonCardHeader>
            
        <IonCardContent style={{backgroundColor: `#ffffff`, minHeight:'220px',maxHeight:'220px', overflowY:'auto'}}>
            <IonList>
            {
                localShowUebersichtAuschreibungen
            }
            </IonList>
        </IonCardContent> 
        </IonCard>
        :
        null
    )
};

export default KachelLieferantenAusschreibung;
