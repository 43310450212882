import { DataContext } from '../context/DataContext';
import { useContext, useState, useEffect } from 'react';

import {
    IonButton,
    IonChip,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPopover,
    IonSelect,
    IonSelectOption,
    IonTextarea,
} from '@ionic/react';

import { Preferences } from '@capacitor/preferences';
import { trashOutline, saveOutline, addCircleOutline } from 'ionicons/icons';
import { dbQuery } from '@saint/sqlite-worker';
import { Warning } from '@mui/icons-material';
import { useFirstRender } from '@mui/x-data-grid';
import { bworkerQuery } from '@saint/fetch-worker';

type Params = {
    artikel?: any;
    zusatzstoffe?: any;
};

//@ts-ignore
const delay = ms  => new Promise(res => setTimeout(res, ms));

export const LieferantAusschreibungArtikel: React.FC<Params> = ({ artikel, zusatzstoffe }) => {
    let date = new Date();
    let sql_today= date.getUTCFullYear()         + '-' +
    (date.getUTCMonth() + 1)  + '-' +
    (date.getUTCDate())  
    const { appChosenKundenId,server,DBInitedChange } = useContext(DataContext);

    const [localArtikelZusatzstoffe, setLocalArtikelZusatzstoffe]: any = useState([]);
   
    const [localArtikelId, setLocalArtikelId]: any = useState(artikel ? artikel.artikel_id : (appChosenKundenId+'-neu-'+Math.ceil(Math.random()*100000)));
    const [localArtikelBestellnummer, setLocalArtikelBestellnummer]: any = useState(
        artikel.ausschreibung_position_lieferant_bestellnummer ? artikel.ausschreibung_position_lieferant_bestellnummer : null,
    );
    const [localArtikelBezeichnung, setLocalArtikelBezeichnung]: any = useState(
        artikel.ausschreibung_position_lieferant_bezeichnung ? artikel.ausschreibung_position_lieferant_bezeichnung : artikel.bestellung_pos_interne_bezeichnung,
    );
    const [localArtikelBeschreibung, setLocalArtikelBeschreibung]: any = useState(
        artikel ? artikel.artikel_beschreibung : null,
    );
    const [localArtikelBestelleinheit, setLocalArtikelBestelleinheit]: any = useState(
        artikel ? artikel.artikel_bestelleinheit : 'GBND',
    );
    const [localArtikelInhaltBestelleinheit, setLocalArtikelInhaltBestelleinheit]: any = useState(
        artikel ? artikel.artikel_inhalt_bestelleinheit : 1,
    );

    const [localArtikelInhaltBestelleinheitString, setLocalArtikelInhaltBestelleinheitString]: any = useState(
        artikel ? artikel.artikel_inhalt_bestelleinheit : 1,
    );
    const [localArtikelInhaltEinheit, setLocalArtikelInhaltEinheit]: any = useState(
        artikel ? artikel.artikel_basiseinheit : 'STK',
    );
    const [localArtikelInhaltBasisgewicht, setLocalInhaltArtikelBasisgewicht]: any = useState(
        artikel ? 1000 * artikel.artikel_rezept_faktor : 1000,
    );
    const [localArtikelPreis, setLocalArtikelPreis]: any = useState(artikel ? artikel.preise_preis : 0);
    const [localArtikelPreisAb, setLocalArtikelPreisAb]: any = useState(artikel ? artikel.preise_ab : sql_today);
    
    const [localArtikelPreisString, setLocalArtikelPreisString]: any = useState(parseFloat(localArtikelPreis).toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
    }));
    const [localArtikelSteuer, setLocalArtikelSteuer]: any = useState(artikel ? artikel.artikel_steuer_id : 1);
    const [localArtikelKCal, setLocalArtikelKCal]: any = useState(artikel ? artikel.artikel_kcal : 0);
    const [localArtikelKH, setLocalArtikelKH]: any = useState(artikel ? artikel.artikel_kohlenhydrate : 0);
    const [localArtikelFett, setLocalArtikelFett]: any = useState(artikel ? artikel.artikel_fett : 0);
    const [localArtikelGesFett, setLocalArtikelGesFett]: any = useState(artikel ? artikel.artikel_ges_fetts : 0);
    const [localArtikelProtein, setLocalArtikelProtein]: any = useState(artikel ? artikel.artikel_protein : 0);
    const [localArtikelSalz, setLocalArtikelSalz]: any = useState(artikel ? artikel.artikel_salz : 0);
    const [localArtikelZucker, setLocalArtikelZucker]: any = useState(artikel ? artikel.artikel_zucker : 0);
    const [localArtikelGesperrt, setLocalArtikelGesperrt]: any = useState(artikel ? artikel.artikel_gesperrt : 0);
    
    function nichtErlaubteZeichen (string:any){
        let neuerstring=
            string.replaceAll('/','')
            .replaceAll('\\','')
            .replaceAll('ß','')
            .replaceAll('%','')
            .replaceAll("'",'')
            .replaceAll('"','')
            .replaceAll('`','')
            .replaceAll('´','')
            .replaceAll('^','')
            .replaceAll('°','')
            .replaceAll('<','')
            .replaceAll('>','')

        if(neuerstring!==string){
           /*  alert(`Verbotenes Sonderzeichen bitte entfernen!
            ß / \ % ´\` ^ ° < > sind nicht erlaubt
      `) */
        }
        return neuerstring
    }

    const artikel_id = 
      artikel ? artikel.artikel_id : 'neuer_artikel'
    
    const saveArtikel = async () => {
        const artikelDaten = {
            localArtikelKCal:localArtikelKCal,
            localArtikelKH:localArtikelKH,
            localArtikelFett:localArtikelFett,
            localArtikelGesFett:localArtikelGesFett,
            localArtikelProtein:localArtikelProtein,
            localArtikelSalz:localArtikelSalz,
            localArtikelZucker:localArtikelZucker,
            localArtikelId:localArtikelId,
            localArtikelBestellnummer:localArtikelBestellnummer,
            localArtikelBezeichnung:localArtikelBezeichnung,
            localArtikelBeschreibung:localArtikelBeschreibung,
            localArtikelBestelleinheit:localArtikelBestelleinheit,
            localArtikelInhaltBestelleinheit:localArtikelInhaltBestelleinheit,
            localArtikelInhaltEinheit:localArtikelInhaltEinheit,
            localArtikelInhaltBasisgewicht:localArtikelInhaltBasisgewicht,
            appChosenKundenId:appChosenKundenId,
            localArtikelZusatzstoffe:localArtikelZusatzstoffe,
            localArtikelPreis:localArtikelPreis,
            localArtikelPreisAb:localArtikelPreisAb,
            localArtikelSteuer:localArtikelSteuer,
            localArtikelGesperrt:localArtikelGesperrt,
        }

        bworkerQuery(
            {
              url:
                server + '/funktionen/api2/lieferanten/set_artikel.php'
                + '?data=' + JSON.stringify(artikelDaten)
              ,
              method: 'GET'
            }
          )
          
          if(artikel_id=='neuer_artikel'){
            alert('Artikel wird angelegt')
            await delay(1000);
            
            DBInitedChange(false);
          }
          
    }

    useEffect(() => {
        if(typeof(localArtikelPreisString) === 'string'){
            setLocalArtikelPreis(localArtikelPreisString.replace(/[^0-9,-]+/g,"").replace(',','.'))
        }
        
    }, [localArtikelPreisString]);
    return (
        <tr id={artikel_id} style={{}}>
            <td
                className="datacell"
                style={{
                    position: 'sticky',
                    left: 0,
                    border:artikel_id=='neuer_artikel'?'2px red solid':'',
                    backgroundColor:'white',
                    zIndex: 4,
                    maxWidth: '100px',
                    minWidth: '100px',
                }}>
                {artikel.bestellung_pos_interne_artikel_id}
            </td>
            <td
                className="datacell"
                style={{ position: 'sticky', left: '100px', backgroundColor: 'white', zIndex: 4,
                border:artikel_id=='neuer_artikel'?'2px red solid':'',
            }}>
                {artikel.bestellung_pos_interne_bezeichnung}

            </td>
            <td className="datacell" style={{border:localArtikelBestellnummer===null?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonTextarea
                        class="datacellinput"
                        placeholder="Bestellnummer"
                        autoGrow={true}
                        onIonChange={(e) => {
                            setLocalArtikelBestellnummer(nichtErlaubteZeichen(e.target.value));
                        }}
                        value={localArtikelBestellnummer}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:'2px green solid'}}>
                <IonItem>
                    <IonTextarea
                        class="datacellinput"
                        placeholder="Bezeichnung"
                        autoGrow={true}
                        onIonChange={(e) => {
                            setLocalArtikelBezeichnung(nichtErlaubteZeichen(e.target.value));
                        }}
                        value={localArtikelBezeichnung}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelBestelleinheit===null?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Bestelleinheit"
                        onIonChange={(e) => {
                            setLocalArtikelBestelleinheit(e.target.value);
                        }}
                        value={localArtikelBestelleinheit}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelInhaltBestelleinheit===null?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Inhalt"
                        type="number"
                        onIonChange={(e) => {
                            setLocalArtikelInhaltBestelleinheit(e.target.value);
                        }}
                        value={localArtikelInhaltBestelleinheit}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelInhaltEinheit===null?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Einheit Inhalt"
                        type="text"
                        
                        onIonChange={(e) => {
                            setLocalArtikelInhaltEinheit(e.target.value);
                        }}
                        value={localArtikelInhaltEinheit}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelInhaltBasisgewicht===null?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Gewicht Inhalt"
                        type="number"
                        onIonChange={(e) => {
                            setLocalInhaltArtikelBasisgewicht(e.target.value);
                         }}
                        value={localArtikelInhaltBasisgewicht}
                    />
                </IonItem>
            </td>
            
            <td className="datacell" style={{border:localArtikelPreis===0?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Preis je VE"
                        type="text"
                        onIonBlur={(e) => {
                            //@ts-ignore
                            setLocalArtikelPreisString(e.target.value);
                         }}
                        value={parseFloat(localArtikelPreis).toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                        })}
                    />
                </IonItem>
            </td>
            
        </tr>
    );
};
