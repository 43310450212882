import { useContext } from 'react';
import { DataContext } from './../context/DataContext';
import { dbQuery } from '@saint/sqlite-worker';
import { person } from 'ionicons/icons';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonContent,
    IonPage,
    IonCard,
    IonItem,
    IonList,
    IonButton,
    IonIcon,
    IonChip,
    IonLabel,
    IonCardHeader
} from '@ionic/react';
import KachelLieferantenArtikel from '../Kacheln/KachelLieferantenArtikel';
import KachelLieferantenAusschreibung from '../Kacheln/KachelLieferantenAusschreibung';

const Kacheln: React.FC = () => {
    const {
        appChosenKundenId
    } = useContext(DataContext);

    return (
        <IonPage>
            <IonContent fullscreen style={{display: 'flex',
        alignItems: 'center'}}> 
          { appChosenKundenId
                                                ?
                                                <IonGrid>
                                                    <IonRow>
                                                        <IonCol size-md="6" sizeLg='4'>
                                                            <KachelLieferantenArtikel />
                                                        </IonCol>
                                                        <IonCol size-md="6" sizeLg='4'>
                                                            <KachelLieferantenAusschreibung />
                                                        </IonCol>
                                           
                                                     </IonRow>
                                                </IonGrid>
                                      :       <IonChip style={{backgroundColor:"white"}}> <IonLabel>Bitte oben rechts den Kunden wählen</IonLabel><IonIcon icon={person} /></IonChip>
                                            }
                                                
            </IonContent>
        </IonPage>
    );
};

export default Kacheln;
