import { useContext, useState, useEffect } from 'react';
import { DataContext } from '../context/DataContext';
import { dbQuery } from '@saint/sqlite-worker';
import { chevronForward, chevronBack, addCircleOutline, trashBinOutline, search, add, cog, swapVertical, arrowDown } from 'ionicons/icons';
import {
    IonGrid,
    IonLabel,
    IonRow,
    IonCol,
    IonInput,
    IonContent,
    IonPage,
    IonCard,
    IonItem,
    IonList,
    IonButton,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonIcon,
    IonFab,
    IonFabButton,
    IonFabList,
} from '@ionic/react';
import { bworkerQuery } from '@saint/fetch-worker';
import Box from '@mui/material/Box';
import { LieferantArtikelUebersichtArtikel } from '../components/LieferantArtikelUebersichtArtikel';
import zIndex from '@mui/material/styles/zIndex';
import { LieferantAusschreibungArtikel } from '../components/LieferantAusschreibungArtikel';

const LieferantenAusschreibungTabelle: React.FC = () => {
    const { server, appChosenKundenId, appAusschreibungData } = useContext(DataContext);

    const [localLieferantenArtikelUebersicht, setLocalLieferantenArtikelUebersicht] = useState([]);
    const [localLieferantenArtikelGesamt, setLocalLieferantenArtikelGesamt] = useState([]);
    const [localLieferantenArtikelOffset, setLocalLieferantenArtikelOffset] = useState(0);
    const [localLieferantenArtikelSuche, setLocalLieferantenArtikelSuche]: any = useState('');
    const [localLieferantenArtikelSortierung, setLocalLieferantenArtikelSortierung]: any = useState('artikel_lieferanten_artikel_nr');

    const [localLieferantenZusatzstoffliste, setLocalLieferantenZusatzstoffliste] = useState([]);

    const [localShowNeuerArtiel, setLocalShowNeuerArtiel]: any = useState(false);

    function LieferantenArtikelUebersichtLadeDaten() {
        // console.log('localLieferantenArtikelSuche', localLieferantenArtikelSuche);
        
    }

    useEffect(() => {
        if (appChosenKundenId) {
            LieferantenArtikelUebersichtLadeDaten();
        }
    }, [appChosenKundenId, localLieferantenArtikelOffset, localLieferantenArtikelSuche]);

    useEffect(() => {
        if (appChosenKundenId) {
            LieferantenArtikelUebersichtLadeDaten();
        }
    }, [appChosenKundenId, localLieferantenArtikelOffset, localLieferantenArtikelSuche, localLieferantenArtikelSortierung]);

    // useEffect(() => {
    //     //console.log('localLieferantenArtikelUebersicht', localLieferantenArtikelUebersicht.values);
    // }, [localLieferantenArtikelUebersicht]);

    // useEffect(() => {
    //     console.log('localLieferantenArtikelGesamt', localLieferantenArtikelGesamt.values);
    // }, [localLieferantenArtikelGesamt]);

    return (
        <IonPage
            style={{
                background: 'url("../assets/food-bg.jpg")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>
            <IonContent>
                <IonCard style={{ height: '92%', overflow: 'auto' }}>
                    <IonCardHeader>
                        <IonCardTitle><IonButton download={"Preisabfrage_2_Wesel.xlsx"}  href="https://devel-ah.saint-online.de/funktionen/api2/lieferanten/Preisabfrage_2_Wesel.xlsx">Excel Download</IonButton><IonButton>Excel Hochladen</IonButton></IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <table className="datatable" id="datatable">
                            <thead>
                                <tr>
                                <th
                                        style={{
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 5,
                                            maxWidth: '100px',
                                            minWidth: '100px',
                                        }}>
                                        Interne ID
                                    </th>
                                    <th style={{ position: 'sticky', left: "100px",  zIndex: 5 }}>
                                        Interne Bezeichnung
                                    </th>
                                    <th style={{ position: 'sticky', left: "100px",  zIndex: 5 }}>
                                        Artikel Bestellnummer
                                    </th>
                                    <th className="datacelllarge">
                                        Artikel Bezeichnung  
                                    </th>
                                    <th>
                                        Verpackungseinheit
                                    </th>
                                    <th>
                                        Inhalt Verpackung
                                    </th>
                                    <th>
                                        Einheit Inhalt
                                    </th>
                                    <th>
                                        Gewicht je Inhalt in g
                                    </th>
                                    <th>Preis je VE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                
                                appAusschreibungData.data &&
                                        appAusschreibungData.data.length > 0
                                        ?
                                            appAusschreibungData.data.map((ausschreibung: any) => {
                                                                    return(
                                                                            ausschreibung.artikel &&
                                                                            ausschreibung.artikel.length > 0
                                                                            ?
                                                                            ausschreibung.artikel.map((artikel: any) => {
                                                                               return (
                                                                               <LieferantAusschreibungArtikel 
                                                                               key={'artikel_ausschreibung_' + artikel.artikel_id}
                                                                               artikel={artikel}                                                                       
                                                                        
                                                                               />
                                                                                
                                                                               )
                                                                            }
                                                                            
                                                                            )
                                                                            :
                                                                            null
                                                                
                                                                        )
                                                                        }
                                                                )
                                          : null 
                                
                                }
                                
                            </tbody>
                        </table>
                    </IonCardContent>
                </IonCard>
                
            </IonContent>
        </IonPage>
    );
};

export default LieferantenAusschreibungTabelle;
